<template>
  <section id="dashboard-ecommerce">
    <b-row v-if="$can('read', 'product_edit')">

      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Son 2 Yıl Karlılık Tablosu
              </b-card-title>
              <b-card-sub-title />
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>
          <b-card-body>
            <b-table-simple
              v-if="monthlyEarningTable"

              small
              caption-top
              responsive
            >

              <b-thead>

                <b-tr>
                  <b-th>{{ $t('Yıl') }}</b-th>
                  <b-th>{{ $t('Ay') }}</b-th>
                  <b-th>{{ $t('Toplam Alış') }}</b-th>
                  <b-th>{{ $t('Toplam Satış') }}</b-th>
                  <b-th>{{ $t('Toplam Karlılık Oranı') }}</b-th>
                  <b-th>{{ $t('Toplam Kazanç') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(data, index) in monthlyEarningTable"
                  :key="index"
                >
                  <b-td>{{ data.year }}</b-td>
                  <b-td>{{ $staticParams.getMonthName(data.month) }}</b-td>
                  <b-td>{{ $staticParams.formatCurrency(data.total_buy ) }}</b-td>
                  <b-td>{{ $staticParams.formatCurrency(data.total_sell ) }}</b-td>
                  <b-td>{{ $staticParams.formatCurrency(data.total_earning ) }}</b-td>
                  <b-td>  <span v-if="data.earning_rate!=null">%{{ parseFloat(data.earning_rate).toFixed(2) }}</span>
                    <span v-else>VERİ MEVCUT DEĞİL</span>
                  </b-td>

                </b-tr>

              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Son 2 Yıl Stok Geçmişi Tablosu (Her Ayın 1'i Baz Alınır)
              </b-card-title>
              <b-card-sub-title />
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>
          <b-card-body>
            <b-table-simple
              v-if="monthlyStockTable"

              small
              caption-top
              responsive
            >

              <b-thead>

                <b-tr>
                  <b-th>{{ $t('Yıl') }}</b-th>
                  <b-th>{{ $t('Ay') }}</b-th>
                  <b-th>{{ $t('Toplam Stok Bedeli') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(data, index) in monthlyStockTable"
                  :key="index"
                >
                  <b-td>{{ data.year }}</b-td>
                  <b-td>{{ $staticParams.getMonthName(data.month) }}</b-td>
                  <b-td>  <span v-if="data.total_stock_price!=null">{{ $staticParams.formatCurrency(data.total_stock_price ) }}</span>
                    <span v-else>VERİ MEVCUT DEĞİL</span>
                  </b-td>

                </b-tr>

              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <statistic-card-vertical
          v-if="true==false"
          color="secondary"
          icon="FlagIcon"
          :statistic="$staticParams.formatCurrency(stockData.total_stock_price)+' TL'"
          statistic-title="Tüm Mevcut Stokların Toplam Değeri"
        />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import {
  BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTd, BTh, BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import dashboardStoreModule from './dashboardStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    StatisticCardVertical,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
  },
  data() {
    return {
      stockData: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      store
        .dispatch('app-dashboard/fetchStockReport')
        .then(response => {
          this.stockData = response.data.data
        })
        .catch(() => {
        })
    },
  },
  setup() {
    const monthlyEarningTable = ref(null)
    const monthlyStockTable = ref(null)
    const APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-dashboard/fetchMonthlyEarningTable', { list_type: 'all' })
      .then(response => {
        monthlyEarningTable.value = response.data.data
      })

    store.dispatch('app-dashboard/fetchMonthlyStockTable', { list_type: 'all' })
      .then(response => {
        monthlyStockTable.value = response.data.data
      })
    // data

    return { monthlyEarningTable, monthlyStockTable }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
